import React from 'react';
import {ArticleHeroBlockConstants} from '../../adapters/helpers/Constants'
import Block from '../../adapters/helpers/Block';
import PropTypes from 'prop-types'
import Heading from '../Heading/Heading';
import ObLink from '../ObLink/ObLink';
import {Label} from '../Label/Label';
import Image from '../Image/Image';

import Disclaimer from '../Disclaimer/Disclaimer';

export default function LegacyArticleHeroBlock(props) {
    const block = new Block(props);
    const { extraAttributes } = props;
    const className = block.getFieldValue('classNames');
    const anchorId = block.getAnchorId();
    const showImage = extraAttributes?.entity?.articleOverview?.fields?.showImage;    
    const thumbnailImage = extraAttributes?.entity?.articleOverview?.fields?.thumbnailImage;
    const mobileImage =  extraAttributes?.entity?.articleOverview?.fields?.mobileThumbnail;
    const title = extraAttributes?.entity?.articleOverview?.fields?.title;
    const eyebrow = extraAttributes?.entity?.category?.fields?.title;
    const slug = extraAttributes?.entity?.category?.fields?.slug?.fields?.slug;
    const readingTimeLabel = Label({label: block.getFieldValue(ArticleHeroBlockConstants.readTimeLabel), tokens: {readTime: extraAttributes.entity.articleOverview.fields.readTime}});
    const body = extraAttributes?.entity?.articleOverview?.fields?.description;
    const backgroundAsset = extraAttributes?.entity?.backgroundAsset;
    const disclaimer = block?.fields?.disclaimer;

    return (
        <div className={`ob-legacy-article-hero ${className || ''}`} id={anchorId}>
            <div className="ob-legacy-article-hero__inner">
                <ObLink href={`${slug}`}>
                    {eyebrow}
                </ObLink>

                <Heading className="ob-legacy-article-hero__heading" tag="h1">
                    {title}
                </Heading>

                <p className="ob-legacy-article-hero__text">
                    {body}
                </p>
                { readingTimeLabel &&
                    <p className="ob-legacy-article-hero__time">
                        {readingTimeLabel}
                    </p>
                }
             <Image image={backgroundAsset} text={ArticleHeroBlockConstants.articleBanner}   />

                {disclaimer &&
                    <Disclaimer document={disclaimer} />
                }
            </div>
        </div>
    );
}

LegacyArticleHeroBlock.propTypes = {
    extraAttributes: PropTypes.object
};
