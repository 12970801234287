import Cell from './ComparisonChartCell';
import CellHtml from './ComparisonChartCellHtml';
import Row from './ComparisonChartRow';
import times from 'lodash/times';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';
import React, {Fragment, useCallback, useRef} from 'react';
import ImageFactory from '../../adapters/cloudinary/ImageFactory';
import Block from '../../adapters/helpers/Block';
import {ComparisonChartBlockConstants} from '../../adapters/helpers/Constants';
import {smoothVerticalScroll, stripHtml} from '../../adapters/helpers/Utils'
import {Label} from '../Label/Label'
import {Markdown} from '../MarkdownText/MarkdownText';
import Icon from '../Icon/Icon';

const MINIMUM_WIDTH_MOBILE_VW = 40;
const PADDING_HORIZONTAL_MOBILE_PX = 39;

export default function ComparisonChart(props) {
    const {extraAttributes, className } = props;
    const entity = extraAttributes?.entity;
    const block = new Block(props);
    const leftFadeRef = useRef(null);
    const rightFadeRef = useRef(null);
    const noValueLabel = Label({label: block.getFieldValue(ComparisonChartBlockConstants.noValueLabel)});
    const jumpToConfiguratorLink = block.getFieldValue(ComparisonChartBlockConstants.jumpToConfiguratorLink);

    const onChartScroll = useCallback((event) => {
        let scrollRatio = event.target.scrollLeft / (event.target.scrollWidth - event.target.offsetWidth);
        scrollRatio = Math.max(0, scrollRatio);
        scrollRatio = Math.min(1, scrollRatio);

        if(leftFadeRef.current) {
            leftFadeRef.current.style.opacity = scrollRatio;
        }

        if(rightFadeRef.current) {
            rightFadeRef.current.style.opacity = 1 - scrollRatio;
        }
    }, []);

    const series = entity?.series;
    const title = entity?.comparisonChart?.fields?.title;
    const classNames = ['ob-comparison-chart'];

    if(className) {
        classNames.push(className);
    }

    const priceRangeLabels = series?.map(serie => {
        return {
            label : serie.fields?.priceRangeLabel?.fields?.text,
            ariaLabel: serie?.fields?.priceRangeAriaLabel
        };
    });

    const comparisonPoints = series?.map(serie => {
        const comparisonPointFallback = serie?.fields?.comparisonPointsFallback;
        return serie?.fields?.comparisonPoints?.map(comparisonPoint => {
            return {
                label: comparisonPoint?.fields?.text,
                className: comparisonPoint?.fields?.classNames,
                comparisonPointFallback: comparisonPointFallback
            }})
    || [] });

    const jumpToConfigurator = () => {
        const configuratorElem = document.querySelector(jumpToConfiguratorLink?.fields?.url);
        smoothVerticalScroll(configuratorElem, ComparisonChartBlockConstants.top);
    };

    const longestComparision = comparisonPoints?.reduce((p, c) => c.length > p ? c.length : p, 0);

    return (
        <div className={classNames.join(' ')}>
            <h2 className='ob-comparison-chart-title'>{title}</h2>
            {jumpToConfiguratorLink &&
                <button
                    className={'ob-comparison-chart-jump-to-configurator'}
                    onClick={jumpToConfigurator}
                >
                    {jumpToConfiguratorLink?.fields?.title}
                    <Icon name={ComparisonChartBlockConstants.chevronRight} />
                </button>
            }
            <div className='ob-comparison-chart-wrapper-1'>
                {Array.isArray(series) && <div onScroll={onChartScroll} className='ob-comparison-chart-wrapper-2'>
                    {series.map((_, i) => <div key={i} className='ob-comparison-chart-snap-hack' style={{left: `${i * MINIMUM_WIDTH_MOBILE_VW}vw`}}/>)}
                    <div className='ob-comparison-chart-table'
                        role={ComparisonChartBlockConstants.table}
                        style={{width: `calc(${MINIMUM_WIDTH_MOBILE_VW * series.length}vw + ${2* PADDING_HORIZONTAL_MOBILE_PX}px)`}}>
                        <Row>
                            {series.map((currentSeries, i) => {
                                const productVariants = currentSeries?.fields?.featuredProduct?.fields?.productVariants;
                                const shortTitle = currentSeries?.fields?.shortTitle;

                                let altText = undefined;
                                let imageSrc = undefined;

                                if(Array.isArray(productVariants) && productVariants.length > 0) {
                                    const asset = productVariants[0]?.fields?.mainAsset;
                                    altText = asset?.fields?.alternateText;
                                    const assetId = asset?.fields?.assetId;
                                    const contentImageId = asset?.fields?.asset?.fields?.file?.url;
                                    imageSrc = contentImageId && ImageFactory.buildContentfullImageUrlByHeight(contentImageId, '220');
                                }

                                return (
                                    <CellHtml key={i} className='ob-comparison-chart-header' role={ComparisonChartBlockConstants.columnheader}>
                                        <Fragment>
                                            <a href={currentSeries.fields?.featuredProduct?.fields?.slug?.fields?.slug}
                                               className='event_internal_link'
                                               data-action-detail={stripHtml(shortTitle)} title={stripHtml(shortTitle)} aria-label={stripHtml(shortTitle)}>
                                                <div className='ob-comparison-chart-image'>
                                                    <LazyLoad offset={100}>
                                                        <img style={{height: '220px'}}  alt={`${ComparisonChartBlockConstants.altTextPrefixFeatureImage}-${altText}`} src={imageSrc} />
                                                    </LazyLoad>
                                                </div>
                                                <span className='ob-comparison-chart-short-title' dangerouslySetInnerHTML={{__html: shortTitle}}></span>
                                            </a>
                                        </Fragment>
                                    </CellHtml>
                                )
                            })}
                        </Row>
                        <Row>
                            {priceRangeLabels.map((priceRangeLabel, i) =>
                                <Cell key={i} className='ob-comparison-chart-price-range' ariaLabel={priceRangeLabel.ariaLabel} role={ComparisonChartBlockConstants.cell}>{priceRangeLabel.label}</Cell>)
                            }
                        </Row>
                        {times(longestComparision, i =>
                            <Row key={`feature-row__${i}`}>

                                {comparisonPoints.map((comparisonPoint, j) =>
                                    <Cell key={j} className='ob-comparison-chart-feature-cell' role={ComparisonChartBlockConstants.cell}>
                                        {comparisonPoint[i] ? (
                                            // ClassName visuallyhidden can be passed through the data for A11y text
                                            // else it'll use the fallback
                                            <Markdown className={comparisonPoint[i].className} source={comparisonPoint[i].label}/>
                                        ) : (
                                            // FallBack if empty field
                                            <p className='visuallyhidden'>{comparisonPoint[0]?.comparisonPointFallback || noValueLabel}</p>
                                        )}
                                    </Cell>)}
                            </Row>
                        )}
                    </div>
                </div>}
                <div className='ob-comparison-chart-fade-left' ref={leftFadeRef}/>
                <div className='ob-comparison-chart-fade-right' ref={rightFadeRef}/>
            </div>
        </div>
    )
}

ComparisonChart.propTypes = {
    extraAttributes: PropTypes.any,
    className: PropTypes.any,
    props: PropTypes.object,
}

ComparisonChart.defaultProps = {
    extraAttributes: {},
}
