import React, {useEffect} from 'react';
import { connectStateResults } from 'react-instantsearch-dom';
import { algoliaReducerConstants } from '../../../../adapters/helpers/Constants'

const StateResults = ({ searchResults,state,dispatch }) => {
  const nbHits = state && state.nbHits;
  let firstIndex = state?.pageNoClicked > 1 ? 10* (state?.pageNoClicked - 1) + 1 : 1
  let lastIndex =  state?.pageNoClicked > 1 ? 10* (state?.pageNoClicked - 1): 10

  if(searchResults){
    let recordsInLastPage =  searchResults?.hits.length % 10
    if(state.nbHits <= 10 && state.nbPages == 1){
      lastIndex = state.nbHits
      firstIndex = 1
    }
    else{
      if(state.pageNoClicked == state.nbPages){
        lastIndex = firstIndex + (recordsInLastPage-1)
      }
      else{
        lastIndex = firstIndex + 9
      }
    }
  } 

  let resultsTitle = state?.searchStaticContent.resultLabel ? state?.searchStaticContent.resultLabel
    .replace('${QUERY}', state.queryParm)
    .replace('${FIRST_INDEX}', firstIndex)
    .replace('${LAST_INDEX}', lastIndex)
    .replace('${TOTAL_RESULTS}',nbHits) : '';

  
    useEffect(() => {
      if(!state.showAutocomplete && state.searchValue != '' && (state.searchValue == state.queryParm)){
        dispatch({ type: algoliaReducerConstants.pageNoClicked,payload:searchResults.page+1,...state });
        dispatch({ type: algoliaReducerConstants.totalPageNumber,payload:searchResults.nbPages,...state });   
        dispatch({ type: algoliaReducerConstants.isSpinning, payload: false, ...state });
        dispatch({ type: algoliaReducerConstants.nbHits, payload: searchResults.nbHits, ...state });
        dispatch({ type: algoliaReducerConstants.nbPages, payload: searchResults.nbPages, ...state });
      }
    }, [state.hitsResponse])
  return (
    <div className="ob-search-block__results-title" dangerouslySetInnerHTML={{__html: resultsTitle}}/>
    
  );
};

const CustomStateResults = connectStateResults(StateResults);
export default CustomStateResults;