import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Block from '../../adapters/helpers/Block';
import { stripHtml } from '../../adapters/helpers/Utils';
import { mediaQueryHOC } from '../../adapters/helpers/Hooks';
import { getContentfulData } from '../../adapters/helpers/siteMapQueryHelper'

class SitemapPage extends Component {

    constructor(props) {
        super(props);
        this.block = new Block(props);
        this.className = this.block.getFieldValue('classNames');
        this.id = this.block.getAnchorId();
        this.pageLabel = this.block.getFieldValue('pageHeader');
        this.productLabel = this.block.getFieldValue('leftSection');
        this.articleLabel = this.block.getFieldValue('rightSection');
        this.additionalLeftSection = this.block.getFieldValue('additionalLeftSectionFields');
        this.additionalRightSection = this.block.getFieldValue('additionalRightSectionFields');
        this.state = {
            sitemap: null,
            productcategories: [],
            articlecategories: [],
            faqcategories:[]
        };
    }
    async componentDidMount() {
        const sitemapData = await getContentfulData();
        if (sitemapData) {
            this.setProducts(sitemapData.productOverview)
            this.setArticles(sitemapData.articleOverview)
            this.setFaq(sitemapData.faq)
            this.setState({ sitemap: sitemapData })
        }
    }
    setProducts(products) {
        const result = products.reduce(function (r, a) {
            r[a.categories] = r[a.categories] || [];
            r[a.categories] = r[a.categories] || [];
            r[a.categories].push(a);
            return r;
        }, Object.create(null));
        this.setState({ productcategories: result })
    }
    setFaq(faq) {
        const result = faq.reduce(function (r, a) {
            r[a.categories] = r[a.categories] || [];
            r[a.categories] = r[a.categories] || [];
            r[a.categories].push(a);
            return r;
        }, Object.create(null));
        this.setState({ faqcategories: result })
    }
    setArticles(articles) {
        const result = articles.reduce(function (r, a) {
            r[a.categories] = r[a.categories] || [];
            r[a.categories] = r[a.categories] || [];
            r[a.categories].push(a);
            return r;
        }, Object.create(null));
        this.setState({ articlecategories: result })
    }
    renderHierarchy() {
        return (
            <div className={'ob-sitemap__container'}>
                <h1 className={'ob-sitemap__title'}>{this.pageLabel}</h1>
                <ul className={'ob-sitemap__list'}>
                    {

                        this.renderProducts(this.state.productcategories, 'chevronRight')
                    }
                    {
                        this.renderArticles(this.state.articlecategories, 'chevronRight')
                    }
                </ul>
            </div>
        )
    }

    renderProducts(properties) {
        const self = this;
        return (
            <li className={'ob-sitemap__list-item'}>
                <div>
                    <div>{this.productLabel}</div>
                    <ul id="Hierarchy---Sitemap---Shop-Products">
                        <li>
                            {
                                Object.keys(properties).map(function (key,index) {
                                    return (
                                        <div key={index}>
                                            <div dangerouslySetInnerHTML={{ __html: key }}></div>
                                            <ul id="Hierarchy---Sitemap---Shop-Products---Electric-Toothbrushes">
                                                {self.subCategoryLoad(properties[key])}
                                            </ul>
                                        </div>
                                    )
                                })
                            }
                        </li>
                        {this.additionalLeftSection && this.additionalLeftSection.map((additionalField,index) =>{
                            return (
                                <li key={index} id={additionalField?.fields?.className}>
                                    <a href={additionalField?.fields?.pagePath?.fields?.slug} data-action-detail={stripHtml(additionalField?.fields?.title)}>
                                        {/* {link.image && this.renderImage(link.image)} */}
                                        <span>
                                            <span dangerouslySetInnerHTML={{ __html: additionalField?.fields?.title.toUpperCase() }}></span>
                                        </span>
                                    </a>
                                </li>
                            )
                        })}
                    </ul>
                </div>

            </li>
        )
    }

    subCategoryLoad = (categories) => {
        const result = categories.reduce(function (r, a) {
            r[a.subCategories] = r[a.subCategories] || [];
            r[a.subCategories] = r[a.subCategories] || [];
            r[a.subCategories].push(a);
            return r;
        }, Object.create(null));
        return (
            <li>
                {
                    result && Object.keys(result).map(function (key,index) {
                        return (
                            <div key={index}>
                                {key && key !='null' && key !='undefined' && <div dangerouslySetInnerHTML={{ __html: key }}></div>}
                                <ul id='Hierarchy---Sitemap---Shop-Products---Electric-Toothbrushes' className={!key || key =='null' || key =='undefined' ? 'padding-negative':'padding-positive' }>
                                    {result[key] && result[key].map((destination,destIndex)=>{
                                        return (
                                            <li key={destIndex}>
                                                <a href={destination.slug} data-action-detail={stripHtml(destination.title)}>
                                                    {/* {link.image && this.renderImage(link.image)} */}
                                                    <span>
                                                        <span dangerouslySetInnerHTML={{ __html: destination.title }}></span>
                                                    </span>
                                                </a>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                        )
                    })
                }
            </li>
        )
    }

    renderArticles(properties) {
        const self = this;
        return (
            <li className={'ob-sitemap__list-item'}>
                <div>
                    <div>{this.articleLabel}</div>
                    <ul id="Hierarchy---Sitemap---Oral-Health-Tips-and-Article">
                        <li>
                            {
                                Object.keys(properties).map(function (key,index) {
                                    return (
                                        <div key={index}>
                                            <div dangerouslySetInnerHTML={{ __html: key }}></div>
                                            <ul id='Hierarchy---Sitemap---Oral-Health-Tips-and-Article---Why-Oral-B'>
                                                {self.subCategoryLoad(properties[key])}
                                            </ul>
                                        </div>
                                    )
                                })
                            }
                        </li>
                        <li>
                            {
                                Object.keys(this.state.faqcategories).map(function (key,index) {
                                    return (
                                        <div key={index}>
                                            <div dangerouslySetInnerHTML={{ __html: key }}></div>
                                            <ul id='Hierarchy---Sitemap---Oral-Health-Tips-and-Article---Why-Oral-B'>
                                                {self.subCategoryLoad(self.state.faqcategories[key])}
                                            </ul>
                                        </div>
                                    )
                                })
                            }
                        </li>
                        {this.additionalRightSection && this.additionalRightSection.map((additionalField,index) =>{
                            return (
                                <li key={index} id={additionalField?.fields?.className}>
                                    <a href={additionalField?.fields?.pagePath?.fields?.slug} data-action-detail={stripHtml(additionalField?.fields?.title)}>
                                        {/* {link.image && this.renderImage(link.image)} */}
                                        <span>
                                            <span dangerouslySetInnerHTML={{ __html: additionalField?.fields?.title.toUpperCase() }}></span>
                                        </span>
                                    </a>
                                </li>
                            )
                        })}
                    </ul>
                </div>

            </li>
        )
    }
    renderPageLink(dest) {
        const slug = dest.slug.slug;
        return this.renderLinkAndTitle(dest.title, slug, null, null);
    }
    renderLinkAndTitle(title, url, onClick, classNames = null) {
        return (
            <li>
                <a href={url} data-action-detail={stripHtml(title)}
                    onClick={onClick} target={undefined}
                    rel={undefined} className={classNames}>
                    <span>
                        <span dangerouslySetInnerHTML={{ __html: title }}></span>
                    </span>
                </a>
            </li>

        )
    }
    render() {
        return (
            <div className={'ob-sitemap'}>
                {this.renderHierarchy()}
            </div>
        )
    }
}

export default mediaQueryHOC(SitemapPage);

SitemapPage.propTypes = {
    extraAttributes: PropTypes.any,
    isMedium: PropTypes.any,
};
