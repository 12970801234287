import {WarrantyExtensionConstants} from '../../../helpers/Constants';
import {customJsonstringify, formatDateToStringValue} from '../../../helpers/Utils';
import ImageFactory from '../../../cloudinary/ImageFactory';

// FORM HEADERS
export function generateHeader() {
    let header = new Headers();
    header.append(WarrantyExtensionConstants.contentType, WarrantyExtensionConstants.json);
    header.append(WarrantyExtensionConstants.accessToken, process.env.WARRANTY_API_ACCESS_TOKEN);

    return header;
}

export function isOldConsumer(consumerId) {
    consumerId = (consumerId || '').toString();
    return consumerId.startsWith('JR') || consumerId.startsWith('PC');
}

export function generateEmailNotificationHeader() {
    let header = new Headers();
    header.append(WarrantyExtensionConstants.contentType, WarrantyExtensionConstants.json);
    header.append(WarrantyExtensionConstants.accessToken, process.env.WARRANTY_API_EMAIL_NOTIFICATION_ACCESS_TOKEN);

    return header;
}

export const warrantyExtensionUrl = process.env.WARRANTY_API_BASE_URL 
    + process.env.WARRANTY_API_VERSION + WarrantyExtensionConstants.warrantyExtensionPath
    + '?' + WarrantyExtensionConstants.programIdParam + process.env.WARRANTY_API_PROGRAM_ID
    + '&' + WarrantyExtensionConstants.localeParam + process.env.WARRANTY_API_LOCALE;

export const warrantyGetConsumerIdUrl = process.env.WARRANTY_API_BASE_URL 
    + process.env.WARRANTY_API_VERSION + WarrantyExtensionConstants.getConsumerPath
    + '?' + WarrantyExtensionConstants.programIdParam + process.env.WARRANTY_API_PROGRAM_ID
    + '&' + WarrantyExtensionConstants.localeParam + process.env.WARRANTY_API_LOCALE;

export const warrantyExtensionGetProductsByTypeCode = process.env.WARRANTY_API_BASE_URL 
+ process.env.WARRANTY_API_VERSION + WarrantyExtensionConstants.getProductsByTypeCodePath
+ '?' + WarrantyExtensionConstants.programIdParam + process.env.WARRANTY_API_PROGRAM_ID
+ '&' + WarrantyExtensionConstants.localeParam + process.env.WARRANTY_API_LOCALE;

export const warrantyExtensionGetCampaignInfoMaster = process.env.WARRANTY_API_BASE_URL 
+ process.env.WARRANTY_API_VERSION + WarrantyExtensionConstants.getCampaignInfoMaster
+ '?' + WarrantyExtensionConstants.programIdParam + process.env.WARRANTY_API_PROGRAM_ID
+ '&' + WarrantyExtensionConstants.localeParam + process.env.WARRANTY_API_LOCALE;

export const warrantyExtensionGetProductList = process.env.WARRANTY_API_BASE_URL 
+ process.env.WARRANTY_API_VERSION + WarrantyExtensionConstants.getproductListBySeries
+ '?' + WarrantyExtensionConstants.programIdParam + process.env.WARRANTY_API_PROGRAM_ID
+ '&' + WarrantyExtensionConstants.localeParam + process.env.WARRANTY_API_LOCALE;

export const getProductByTypeCode = async (typeCode) => {
    const url = warrantyExtensionGetProductsByTypeCode
    const requestBody = {
        'sType': Number(typeCode)
    }
    const warrantyHeader = generateHeader();
    const requestOptions = {
        method: 'POST',
        headers: warrantyHeader,
        body: customJsonstringify(requestBody)
    };
    try {
        const response = await fetch(url, requestOptions).then(response => response)
        return response
    } catch (error) {
        console.error('get product by typecode error=>', error)
        return {message: 'Server Error'};
    }
}

export const getProductBySeries = async (lstInternSubGroup, lstSeries) => {
    let response = null;
    try {
        const requestBody = {
            lstInternSubGroup: [...lstInternSubGroup],
            lstSeries: [...lstSeries]
        };
        const warrantyHeader = generateHeader();
        const requestOptions = {
            method: 'POST',
            headers: warrantyHeader,
            body: customJsonstringify(requestBody)
        };
        const data = await fetch(warrantyExtensionGetProductList, requestOptions).then(response => response.json())
        if (data.error) {
            console.error('Product Fetch error', data.error)
        } else {
            response = data;
        }
    } catch (error) {
        console.error('series fetch error', error)
    }
    return response;
};

export const postWarrantyRegistration = (product, janrainProfile, newJanrainProduct, postWarrantyOnSuccess = function(){}, postWarrantyOnFailure = function(){}) => {

    const getConsumerIDSuccess = (product, janrainProfile, newJanrainProduct, consumerID = null, onSuccess = function(){}, onFailure = function(){}) => {
        let registration = {
            program: {
                programId: parseInt(process.env.WARRANTY_API_PROGRAM_ID),
                locale: process.env.WARRANTY_API_LOCALE,
                sourceSystemId: parseInt(process.env.WARRANTY_API_SOURCE_SYSTEM_ID)
            },
            consumer: {
            },
            address: [],
            productOrder: {
                product: [
                    {
                        productId: WarrantyExtensionConstants.productId,
                        productCode: product.primary.typeCode,
                        productPurchased: product.primary.longDescription,
                        productQuantity: 1,
                        productName: product.primary.productName,
                        dateOfPurchase: formatDateToStringValue(product.primary.purchaseDate, 'DD/MM/YYYY', 'YYYY-MM-DD'),
                        productImageUrl: product.primary.mainAsset
                    }
                ]
            }
        }

        if(product.secondary){
            registration.productOrder.product.push({
                productId: WarrantyExtensionConstants.productId,
                productCode: product.secondary.typeCode,
                productPurchased: product.secondary.longDescription,
                productQuantity: 1,
                productName: product.secondary.productName,
                dateOfPurchase: formatDateToStringValue(product.secondary.purchaseDate, 'DD/MM/YYYY', 'YYYY-MM-DD'),
                productImageUrl: product.secondary.mainAsset
            })
        }
        if (janrainProfile?.traitProductType?.length == 0) {
            registration.productOrder.product[0].isPrimary = true;
        }


        // After user create profile. There is a delay to get the consumerID.
        // For that reason we need to give the uuid to identify the user we are registering the product to.
        if (janrainProfile?.uuid) {
            registration.consumer.UUID = janrainProfile?.uuid;
        }


        // GCS consumerID will be available after first product registration. It works that way on their side.
        // They want us to add it to the call on subsequent product registration.
        if (consumerID) {
            registration.consumer.consumerId = consumerID;
        }

        if (janrainProfile?.consumerID || janrainProfile?.uuid) {
            registration.consumer.oneCPId = isOldConsumer(janrainProfile?.consumerID) ? janrainProfile?.consumerID : janrainProfile?.uuid;
        }

        if(janrainProfile?.countryId) {
            registration.address.push({
                addressTypeCode: WarrantyExtensionConstants.addressTypeCode,
                countryId: janrainProfile?.countryId
            })
        }

        const warrantyHeader = generateHeader();
        const requestOptions = {
            method: 'POST',
            headers: warrantyHeader,
            body: customJsonstringify(registration)
        };
        fetch(warrantyExtensionUrl, requestOptions)
            .then(response => response.json())
            .then(data => {
                if(data.error) {
                    console.error(data.error);
                    onFailure();
                } else {
                    onSuccess(newJanrainProduct, data);
                }
            })
            .catch((error) => {
                console.error(error);
                onFailure();
            });
    }

    getConsumerId(product, janrainProfile, newJanrainProduct, postWarrantyOnSuccess, postWarrantyOnFailure, getConsumerIDSuccess);

}

export const getCountryId = async (countryValue) => {
    let countryId = -1;
    try {
        const warrantyHeader = generateHeader();
        const requestOptions = {
            method: 'GET',
            headers: warrantyHeader,
        };
        const data  = await fetch(warrantyExtensionGetCampaignInfoMaster, requestOptions).then(response => response.json())
        if(data.error) {
            console.error('country ID find error', data.error)
        } else if(Array.isArray(data.programCountriesForAddress) && data.programCountriesForAddress.length > 0) {
            countryId = data.programCountriesForAddress.filter(country => country.countryCode === countryValue)[0].countryId
        } else {
            console.error('country ID not found', data)
        }
    } catch(error) {
        console.error('country ID find error', error)
    }
    return countryId;
}

export const postWarrantyRegistrationUpdate = (product, janrainProfile, makePrimary = false, isDelete = false, onSuccess = function(){}, onFailure = function(){}, postObject, onPostReplaceSuccess = function(){}) => {
    let registration = {
        productId: product.gCSProductId,
        orderId: product.id,
        UUID: janrainProfile?.uuid,
        oneCPId: isOldConsumer(janrainProfile?.consumerID) ? janrainProfile?.consumerID : janrainProfile?.uuid,
        isPrimary: makePrimary,
        isDelete: isDelete,
        orderProductId: product?.orderProductId
    }

    const warrantyHeader = generateHeader();
    const requestOptions = {
        method: 'PUT',
        headers: warrantyHeader,
        body: customJsonstringify(registration)
    };
    fetch(warrantyExtensionUrl, requestOptions)
        .then(response => response.json())
        .then(data => {
            if(data.code) {
                if(data.code == 'SUCCESS') {
                    onSuccess(postObject, onPostReplaceSuccess);
                } else {
                    onFailure(postObject);
                }
            } else {
                console.error(data.error);
            }
        })
        .catch((error) => {
            console.error(error);

        });
}

// Email notification send on congratulation page when clicking resend CTA.
// Used on FR, DE, NL, UK markets
export const postEmailNotification = (janrainProfile, product, callback = null) => {
    // create image url
    const finalTransformations = '';
    const image = { fields: { assetId: product?.mainAsset?.fields?.asset?.fields?.file?.url }};
    const imageUrl = ImageFactory.imageContentfullSrc(image, finalTransformations);

    let emailRequestBody = { email : janrainProfile?.email,
        parameters: [
            {
                name: 'fname',
                value: janrainProfile?.givenName
            }, {
                name: 'lineproduct',
                value: product?.title
            }, {
                name: 'itemurl1',
                value: imageUrl
            }, {
                name: 'productname',
                value: product?.title
            }, {
                name: 'typecode',
                value: product?.typeCode || ''
            }, {
                name: 'dateofpurchase',
                value: product?.traitDate
            }, {
                name: 'dateofregistration',
                value: product?.registrationDate
            }, {
                name: 'warrantyexpirationdate',
                value: product?.warrantyExpirationDate
            }, {
                name: 'email',
                value: janrainProfile?.email
            }
        ],
        campaignId:parseInt(process.env.WARRANTY_API_PROGRAM_ID),
        templateType:process.env.WARRANTY_API_EMAIL_TEMPLATE_TYPE,
        locale:process.env.WARRANTY_API_LOCALE,
        system:process.env.WARRANTY_API_EMAIL_SYSTEM
    }

    const warrantyHeader = generateEmailNotificationHeader();
    const requestOptions = {
        method: 'POST',
        headers: warrantyHeader,
        body: customJsonstringify(emailRequestBody)
    };
    fetch(process.env.WARRANTY_API_EMAIL_NOTIFICATION_URL, requestOptions)
        .then(response => response.json())
        .then(data => {
            if (data == null) {
                console.log('No data fetched')
            }
        })
        .catch((error) => {
            console.error('failed to notification send email', error);})
        .finally(() => {
            if (callback) {
                callback();
            }
        });
}

export const getConsumerProduct = (onSuccess = function(){}, onFailure = function(){},
                                   profileData, productCategoryList, warrantyExtensionTime) => {
    const warrantyHeader = generateHeader();
        warrantyHeader.append(WarrantyExtensionConstants.UUID, profileData?.uuid);
        warrantyHeader.append(WarrantyExtensionConstants.oneCPId, profileData?.consumerID);

    const requestOptions = {
        method: 'GET',
        headers: warrantyHeader,
    };
    fetch(warrantyExtensionUrl, requestOptions)
        .then(response => response.json())
        .then(data => {
            if(data.error) {
                if (data.error.code == 'PRODUCT_NOT_FOUND' && data.error.statusCode == 400) {
                    // user has no product, give empty array to success method.
                    onSuccess([], profileData, productCategoryList, warrantyExtensionTime);
                }
            } else if(Array.isArray(data)) {
                onSuccess(data, profileData, productCategoryList, warrantyExtensionTime);
            } else {
                onFailure();
            }
        })
        .catch((error) => {
            onFailure();
            console.error(error);
        })
}

export const getConsumerId = (product, janrainProfile, newJanrainProduct, postWarrantyOnSuccess = function(){}, postWarrantyOnFailure = function(){}, getConsumerIDSuccess = function(){}, getConsumerIDFailure = function(){}) => {
    const warrantyHeader = generateHeader();
    warrantyHeader.append(WarrantyExtensionConstants.UUID, janrainProfile?.uuid);

    const requestOptions = {
        method: 'GET',
        headers: warrantyHeader,
    };
    fetch(warrantyGetConsumerIdUrl, requestOptions)
        .then(response => response.json())
        .then(data => {
            if (data.error) {
                if (data.error.code == 'CONSUMER_NOT_FOUND' && data.error.statusCode == 400) {
                    // user has no consumerID yet, give empty consumerID to success method.
                    getConsumerIDSuccess(product, janrainProfile, newJanrainProduct, null, postWarrantyOnSuccess, postWarrantyOnFailure);
                }
            } else if (Array.isArray(data)) {
                let consumerID = data[0].ConsumerId;
                getConsumerIDSuccess(product, janrainProfile, newJanrainProduct, consumerID, postWarrantyOnSuccess, postWarrantyOnFailure);
            } else {
                getConsumerIDFailure();
                console.error(data);
            }
        })
        .catch((error) => {
            getConsumerIDFailure();
            console.error(error);
        })
}

// Second warranty notification email post. Used on SE, FI, IT markets.
// Is called on product registration and when resend email CTA is triggered
export const postGWEProductWarrantyEmailNotification = (janrainProfile, orderId, callback = null) => {
    let emailRequestBody = {
        programId:parseInt(process.env.WARRANTY_API_PROGRAM_ID),
        locale:process.env.WARRANTY_API_LOCALE,
        janrain : {
            UUID: janrainProfile?.uuid
        },
        user : {
            emailAddress: janrainProfile?.email,
            firstName: janrainProfile?.givenName
        },
        orderId : orderId
    }

    const warrantyHeader = generateHeader();
    const requestOptions = {
        method: 'POST',
        headers: warrantyHeader,
        body: customJsonstringify(emailRequestBody)
    };
    fetch(process.env.WARRANTY_API_EMAIL_NOTIFICATION_GWE_URL, requestOptions)
        .then(response => response.json())
        .then(data => {
            if (data == null) {
                console.log('No data fetched');
            }
        })
        .catch((error) => {
            console.error('failed to notification send email', error);})
        .finally(() => {
            if (callback) {
                callback();
            }
        });
}
