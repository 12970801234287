import Image from '../Image/Image';
import React from 'react';

import ImageFactory from '../../adapters/cloudinary/ImageFactory';
import Block from '../../adapters/helpers/Block';
import LazyLoad from 'react-lazyload';

import Backdrop from '../Backdrop/Backdrop';
import Eyebrow from '../Eyebrow/Eyebrow';
import Heading from '../Heading/Heading';

import {AppDownloadBlockConstants} from '../../adapters/helpers/Constants';

import MarkdownText from '../MarkdownText/MarkdownText';
import Disclaimer from '../Disclaimer/Disclaimer';
import Button from '../Button/Button';

export default function AppDownloadBlock(props) {
    const block = new Block(props);

    const className = props?.document?.fields?.classNames || '';
    const anchorId = block.getAnchorId();

    const title = props?.document?.fields?.title;
    const titleLevel=props?.document?.fields.titleLevel;
    const eyebrow = props?.document?.fields?.eyebrow;
    const backgroundAsset = props?.document?.fields?.backgroundAsset;
    const mobileBackgroundAsset = props?.document?.fields?.mobileBackgroundAsset;
    const links = props?.document?.fields?.downloadLinks;
    const body = props?.document?.fields?.body;
    const disclaimer = props?.document?.fields?.disclaimer;
    const hasDivider = props?.document?.fields?.hasDivider;
    const horizontalAlignment = props?.document?.fields?.horizontalAlignment;
    const verticalAlignment = props?.document?.fields?.verticalAlignment;
    const textAlignment = props?.document?.fields?.textAlignment;
    const desktopMainAsset = props?.document?.fields?.desktopMainAsset;
    const mobileMainAsset = props?.document?.fields?.mobileMainAsset;

    const storeAssets = links?.map(link => {
        const image = link?.fields?.image;
        const alt = image?.fields?.alternateText;
        const assetId = image?.fields?.assetId;
        const contentImageId = image?.fields?.asset?.fields?.file?.url;
        let imageUrl = contentImageId && {mobile: ImageFactory.buildContentfullImageUrlByHeight(contentImageId,'50'), desktop: ImageFactory.buildContentfullImageUrlByHeight(contentImageId,'65')};

        return {
            url: link?.fields?.url,
            title: link?.fields?.title,
            alt,
             image : imageUrl
        }
    });

    return (
        <Backdrop
            className={`ob-app-download ${className}`}
            id={anchorId}
            data-horizontal-alignment={horizontalAlignment}
            data-vertical-alignment={verticalAlignment}
            data-text-alignment={textAlignment}
            data-has-disclaimer={disclaimer != null}
        >
            <Backdrop.Image image={backgroundAsset} mobileImage={mobileBackgroundAsset} />

            <Backdrop.Content className='ob-app-download-backdrop-wrapper'>
                <div className='ob-app-download-main-wrapper'>
                    <div className='ob-app-download-content-wrapper'>
                        <div className='ob-app-download-content'>
                            <Eyebrow className='ob-app-download-eyebrow' blueEyebrow={className.includes('blue-eyebrow')} whiteText={className.includes('white-text')}>{eyebrow}</Eyebrow>
                            <Heading className='ob-app-download-heading' tag={`h${titleLevel}`} whiteText={className.includes('white-text')}>{title}</Heading>
                            {hasDivider !== 'No' &&
                                <span className='ob-app-download-divider'></span>
                            }
                            {mobileMainAsset &&
                            <div className={'ob-app-download__mainAssetContainer ob-app-download__mainAssetContainer-mobile'}>
                                <Image image={mobileMainAsset}/>
                            </div>
                            }
                            {body && <MarkdownText className='ob-app-download-body' value={body} /> }
                            <div className='ob-app-download-stores'>
                            {
                                storeAssets?.map((asset, i) => asset.image
                                    ? (
                                        <>
                                            <LazyLoad offset={500}>
                                            <a key={i} className='event_button_click ob-app-download-store' href={asset.url} target={'_blank'} aria-label={`${asset.alt} ${AppDownloadBlockConstants}`} rel={'noopener noreferrer'} data-action-detail={asset.alt}>
                                                    <img data-format={AppDownloadBlockConstants.mobile} alt={asset.alt} src={asset.image.mobile} />
                                                    <img data-format={AppDownloadBlockConstants.desktop} alt={asset.alt} src={asset.image.desktop} />
                                                </a>
                                            </LazyLoad>
                                        </>
                                    ) : (
                                        <Button whiteTheme={true} tag={'a'} key={i} aria-label={`${asset.alt} ${AppDownloadBlockConstants}`} className='event_button_click' href={asset.url} target={'_blank'} dataActionDetail={asset.title}>
                                            {asset?.title}
                                        </Button>
                                    )
                                )
                            }
                            </div>
                        </div>
                        {disclaimer &&
                            <Disclaimer document={disclaimer} />
                        }
                    </div>
                    {desktopMainAsset &&
                    <div className={'ob-app-download__mainAssetContainer ob-app-download__mainAssetContainer-desktop'}>
                        <Image image={desktopMainAsset}/>
                    </div>
                    }
                </div>

            </Backdrop.Content>
        </Backdrop>
    )
}
