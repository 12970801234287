import React, { useState, useContext, useRef } from 'react';
import {stripHtml} from '../../adapters/helpers/Utils';
import Image from '../Image/Image';
import Button from '../Button/Button';
import Block from '../../adapters/helpers/Block';
import Heading from '../Heading/Heading';
import Modal from 'react-modal';
import Icon from '../Icon/Icon';
import { Transition } from 'react-transition-group';
import OnePageScrollContext from '../OnePageScroll/OnePageScrollContext';
import SpotlightExperienceProduct from './SpotlightExperienceProduct';
import PropTypes from 'prop-types';
import {SpotlightExperienceConstants} from '../../adapters/helpers/Constants';

import { mediaQueryHOC } from '../../adapters/helpers/Hooks'

function SpotlightExperience(props) {
    const {document: doc, isMedium, isLarge, index} = props;
    const block = new Block(doc);

    const context = useContext(OnePageScrollContext);
    const currentIndex = context?.currentIndex || 0;
    const isActiveBlock = (index === currentIndex);

    const [selectedProduct, setSelectedProduct] = useState(0);
    const [selectedImage, setSelectedImage] = useState(false);
    const [selectedSku, setSelectedSku] = useState(false);
    const [ctaLabel, setCtaLabel] = useState(false);
    const [modalContent, setModalContent] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const description = block.getFieldValue(SpotlightExperienceConstants.description);
    const title = block.getFieldValue(SpotlightExperienceConstants.title);
    const anchorId = block.getFieldValue(SpotlightExperienceConstants.anchorId);
    const classNames = block.getFieldValue(SpotlightExperienceConstants.classNames);
    const backgroundAsset = block.getFieldValue(SpotlightExperienceConstants.backgroundAsset);
    const productList = block?.props?.fields?.productOverview;
    const closeModalLabel = block.getFieldValue(SpotlightExperienceConstants.closeLabel);
    const countrySelectLabel = block.getFieldValue(SpotlightExperienceConstants.countrySelectLabel);
    const countryList = block?.props?.fields?.countrySelectList;

    const modalRef = useRef(null);

    const customStylesModal = {
        overlay: {
            backgroundColor: 'none'
        },
        content : {
            position            : 'fixed',
            border              : 'none',
            top                 : '0',
            left                : '0',
            right               : '0',
            bottom              : '0',
            padding             : '0',
            marginRight         : '0',
            height              : '100%',
            boxShadow           : 'rgba(0, 0, 0, 0.5) 0 2px 4px',
            background          : 'rgba(255, 255, 255, 0.5)'
        }
    };


    const handleProductChange = (selectedIndex) => {
        setSelectedProduct(selectedIndex);
    };

    const handleModalChange = (newContent) => {
        if(newContent) {
            setModalContent(newContent);
            setModalIsOpen(true);
        }
    };

    const handleImageChange = (newSelectedImage) => {
        if(newSelectedImage) {
            setSelectedImage(newSelectedImage);
        }
    };

    const handleCtaChange = (newSelectedSku, newCtaLabel) => {
        if(newSelectedSku) {
            setSelectedSku(newSelectedSku);
        }

        if(newCtaLabel) {
            setCtaLabel(newCtaLabel);
        }
    };

    const changeCountryLocale = (newLocale) => {
        if (newLocale) {
            let newLanguage = newLocale.split('-')[0];
            let newCountry = newLocale.split('-')[1];

            if(newLanguage && newCountry) {
                newLanguage = newLanguage.toLowerCase();
                newCountry = newCountry.toUpperCase();
                props.onClickCallback(event);

                document.querySelector('meta[name="ps-country"]').setAttribute('content', newCountry);
                document.querySelector('meta[name="ps-language"]').setAttribute('content', newLanguage);
            }
        }
    };

    const renderModalContent = () => {
        if(modalContent) {
            const blockObj = new Block(modalContent);

            return (
                <div className={'ob-spotlight-experience__modal-content'}>
                    {blockObj.renderBlockFromDocument()}
                </div>
            );
        } else {
            return (
                <div className={'ob-spotlight-experience__modal-content'}></div>
            );
        }
    };

    const closeExperienceModal = () => {
        setModalIsOpen(false);
    };

    return (
        <Transition in={index === undefined} timeout={600}>
            {() => (
                <section className={`ob-spotlightContentBlock ob-spotlight-experience ${classNames}`} id={anchorId} data-isactiveblock={isActiveBlock} >
                    <Image image={backgroundAsset && !isLarge ? backgroundAsset : undefined} customStyles={{backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'bottom center'}}>
                        {(!isLarge && selectedImage) &&
                            <Image className="ob-spotlight-experience__product-pic-image__desktop"
                                   image={selectedImage}
                            />
                        }

                        <div className={`ob-spotlight-experience__wrapper ${!backgroundAsset && !isLarge ? 'no-background' : ''}`}>
                            <div className={'ob-spotlight-experience__content'}>
                                <Heading className={'ob-spotlight-experience__title'}>{title}</Heading>

                                <p className={'ob-spotlight-experience__body'}>{description}</p>

                                {(countryList && countryList.length > 0) &&
                                    <div className={'ob-spotlight-experience__form'}>
                                        {countrySelectLabel &&
                                            <label htmlFor={'spotlight_experience_country'}
                                                   className={'ob-spotlight-experience__form-label'}>{countrySelectLabel}</label>
                                        }

                                        <div className={'ob-spotlight-experience__form-select-container'}>
                                            <select
                                                required={true}
                                                name={'spotlight_experience_country'}
                                                className={'ob-spotlight-experience__form-input --select'}
                                                onBlur={event => changeCountryLocale(event.target.value)}
                                                data-reset-country-code={true}>

                                                {
                                                    countryList.map((selectOption, selectOptionIndex) => {
                                                        const countryLocale = selectOption.fields?.locale;
                                                        const countryLabel = selectOption.fields?.label?.fields?.text;

                                                        return(
                                                            <option value={countryLocale}
                                                                    key={'selectOption-' + selectOptionIndex}>{countryLabel}</option>
                                                        )
                                                    })
                                                }
                                            </select>

                                            <Icon name={SpotlightExperienceConstants.chevronDown} size='1.8' />
                                        </div>
                                    </div>
                                }

                                <div className={'ob-spotlight-experience__row'}>
                                    {(isLarge && selectedImage) &&
                                        <Image className="ob-spotlight-experience__product-pic-image__mobile"
                                               image={selectedImage}
                                        />
                                    }

                                    <div className={'ob-spotlight-experience__column'}>
                                        {productList.length > 0 &&
                                            <ul className="ob-spotlight-experience__product-list">
                                                {productList.map((product, i) => (
                                                    <li key={i} className={`ob-spotlight-experience__product-list__item ${i === selectedProduct ? 'current-product' : ''}`}>
                                                        <SpotlightExperienceProduct
                                                            product={product}
                                                            doc={doc}
                                                            itemIndex={i}
                                                            isSelected={i === selectedProduct}
                                                            onImageChange={handleImageChange.bind(this)}
                                                            onModalChange={handleModalChange.bind(this)}
                                                            onCtaChange={handleCtaChange.bind(this)}
                                                            onProductChange={handleProductChange.bind(this)} />
                                                    </li>
                                                ))}
                                            </ul>
                                        }

                                        {(!isMedium && selectedSku && ctaLabel) &&
                                            <Button className={'event_button_click'}
                                                    dataActionDetail={stripHtml(ctaLabel)}
                                                    onClick={event => props.onClickCallback(event)}
                                                    sku={selectedSku}>{ctaLabel}</Button>
                                        }

                                    </div>
                                </div>

                                {(isMedium && selectedSku && ctaLabel) &&
                                    <Button className={'event_button_click'}
                                            dataActionDetail={stripHtml(ctaLabel)}
                                            onClick={event => props.onClickCallback(event)}
                                            sku={selectedSku}>{ctaLabel}</Button>
                                }
                            </div>
                        </div>

                        <Modal
                            isOpen={modalIsOpen && isActiveBlock}
                            style={customStylesModal}
                            onRequestClose={closeExperienceModal}
                            closeTimeoutMS={250}
                            className={'ob-spotlight-experience__modal-overlay'}
                            overlayClassName={'event_button_click'}
                            ref={modalRef}
                        >
                            <div className='ob-spotlight-experience__modal-container'>

                                {renderModalContent()}

                                <button className='event_close_window ob-modal-close no-shadow'
                                        onClick={closeExperienceModal}
                                        aria-label={closeModalLabel} type='button'>
                                    <Icon name='close' size='2' />
                                </button>

                            </div>
                        </Modal>
                    </Image>
                </section>
            )}
        </Transition>
    );
}

export default mediaQueryHOC(SpotlightExperience);
// This export is for unit testing (do not remove) :
export const SpotlightExperienceTest = SpotlightExperience;

SpotlightExperience.propTypes = {
    onProductChange: PropTypes.func,
    onClickCallback: PropTypes.func,
    index: PropTypes.any,
    isMedium: PropTypes.any,
    isLarge: PropTypes.any,
    document: PropTypes.object,

};
