import React, {Fragment} from 'react'
import componentFactory from '../../components/ComponentFactory';
import { customJsonstringify } from './Utils';

class ArticleBlock {

    constructor(props) {
        this.props = props;
        this.article = props.article;
        this.contents = props.contents || this.article?.contents || [];
        this.contentIndex = props.contentIndex || 0;
        this.promotions = props.promotions || this.article?.relatedPromotions || [];
        this.promotionIndex = props.promotionIndex || 0;
    }

    renderBlock(moduleClass, attributes, moduleBody) {
        const moduleDefinition = componentFactory.getModule(moduleClass);
        if (moduleDefinition) {
            const module = React.createElement(moduleDefinition, attributes, moduleBody);
            return module;
        } else {
            console.info('Unsupported module type : ' + moduleClass);
        }
    }

    renderHtmlBlock(attributes) {
        let { classNames, anchorId, textContent } = attributes.extraAttributes.richTextBlock.fields;
        return (<div
                    id={anchorId}
                    className={`ob-html-block ${classNames}`}
                    dangerouslySetInnerHTML={{__html: textContent}}
                ></div>);
    }

    renderBlockFromDocument(extraAttributes, onClickCallback, customEvent, index) {
        const document = this.getDocument();
        if (document && document.fields && document.fields.contentType) {
            let contentType = document.fields.contentType;
            let blockName = document.fields.name;
            let className = document.fields.classNames;

            let attributes = {
                name: blockName,
                document: document,
                className: className,
                onClickCallback,
                customEvent,
                key: blockName,
                index,
                ...extraAttributes
            };

            if(attributes?.extraAttributes?.richTextBlock?.fields?.textContent) {
                return this.renderHtmlBlock(attributes);
            } else {
                return this.renderBlock(contentType, attributes, null);
            }

        } else {
            console.error('Unsupported block is currently assign to page:' + this.props?.page?.fields?.name);
        }
    }

    /**
     * Render the modules assigned to the page.
     * @returns {*}
     */
    renderChildBlocks(extraAttributes, onClickCallback, customEvent) {
        const doc = this.getDocument();
        return ((doc. fields && doc.fields.blocks) ?
            <Fragment>
                { doc.fields.blocks.map((child, index) => {
                    if(this.isRenderable(child)) {
                        let extraAttributesClone = JSON.parse(customJsonstringify(extraAttributes));
                        let currentExtraAttributes = this.reassignExtraAttributes(extraAttributesClone, child);

                        const props = {
                            document: child,
                            article: this.article,
                            contents:this.contents,
                            contentIndex: this.contentIndex,
                            promotions:this.promotions,
                            promotionIndex: this.promotionIndex
                        };

                        return new ArticleBlock(props)
                            .renderBlockFromDocument(currentExtraAttributes, onClickCallback, customEvent, index)
                    }
                })}
            </Fragment>
            :
            null
        );
    }

    getDocument() {
        let doc = this.props.document;

        if (doc == null) {
            doc = this.props;
        }

        if (!doc) {
            doc = {};
        }

        return doc;
    }

    isRenderable(document) {
        if(document.fields.contentType == 'promoBlock' ){
            return (this.promotionIndex < this.promotions.length);
        } else if(document.fields.contentType == 'richTextBlock') {
            return (this.contentIndex < this.contents.length);
        } else {
            return true;
        }
    }

    reassignExtraAttributes(extraAttributes, document) {
        if(document.fields.contentType == 'promoBlock' && this.promotions.length > 0){
            extraAttributes['extraAttributes']['promo'] = this.promotions[this.promotionIndex];
            if(this.promotionIndex < this.promotions.length) {
                this.promotionIndex++;
            }
        }

        if(document.fields.contentType == 'richTextBlock' && this.contents.length > 0){
            extraAttributes['extraAttributes']['richTextBlock'] = this.contents[this.contentIndex];
            if(this.contentIndex < this.contents.length) {
                this.contentIndex++;
            }
        }
        return extraAttributes;
    }
}

export default ArticleBlock