import React, {useState, useRef, useCallback, useEffect} from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon/Icon';
import { stripHtml } from '../../adapters/helpers/Utils';
import { SearchBarConstants } from '../../adapters/helpers/Constants';
import SearchService from '../../adapters/model/service/swiftype/search/SearchService';

export default function SearchBar(props) {

    const {onSearch, placeholder, searchActionTitle, commonSearchesLabel, isSpinning, defaultValue, isSearchFaqOnly, isAutocompleteEnabled, searchTitle, searchCtaLabel} = props;
    const [autoCompleteResults, setAutocompleteResults] = useState([]);
    const [isAutocompleteActivated, setIsAutocompleteActivated] = useState(false);

    const inputRef = useRef(null);
    const KEY_ESC = 27;
    const KEY_DOWN = 40;
    const KEY_UP = 38;

    useEffect(() => {
        return function cleanup() {
            document.removeEventListener('click', handleBlur);
        };
    }, []);

    const doAutocompleteSearch = async (query) => {
        setIsAutocompleteActivated(true);
        let excludedCategories = [];
        if (!isSearchFaqOnly) {
            excludedCategories.push('faq');
        } else {
            excludedCategories.push('product');
            excludedCategories.push('article');
            excludedCategories.push('collection');
            excludedCategories.push('page');
        }

        await SearchService.suggestResults(query, excludedCategories)
            .then((searchResult) => {
                manageAutoCompleteResult(searchResult);
            })
            .catch(() => {
                closeAutocomplete();
            });
    };

    const manageAutoCompleteResult = useCallback(
        (results) => {
            if (results && results.totalCount > 0 && results.searchResults) {
                document.addEventListener('click', handleBlur);
                let subResults = results.searchResults.slice(0, SearchBarConstants.maximumAutocompleteResults);
                let resultsArray = subResults.map(item => {
                    return getAutocompleteItem(item);
                });
                setAutocompleteResults(resultsArray);
            } else {
                setAutocompleteResults([]);
            }
        },
        []
    );

    const getAutocompleteItem = (item) => {
        if (item.highlight) {
            let title = item.name;

            if (item.highlight.text) {
                title = item.highlight.text;
            } else if (item.highlight.url && !item.highlight.name) {
                title = item.name;
            } else if (item.highlight.name) {
                title = item.highlight.name;
            }

            return {query: item.name, title: title};
        }

        return {query: item.name, title: item.title};
    };

    const closeAutocomplete = useCallback(
        () => {
            document.removeEventListener('click', handleBlur);
            setIsAutocompleteActivated(false);
            setAutocompleteResults([]);
        },
        []
    );

    const handleInput = (event) => {
        let value = '';
        if (event.currentTarget && event.currentTarget.value) {
            value = event.currentTarget.value.trim();
        }

        if (value === '' || value.length <= 2) {
            closeAutocomplete();
        } else if (isAutocompleteEnabled) {
            doAutocompleteSearch(value);
        }
    };

    const handleBlur = () => {
        closeAutocomplete();
    };

    const handleAutocompleteResultClick = useCallback(
        (term) => {
            if (inputRef.current) {
                inputRef.current.blur();
                inputRef.current.value = term;
            }

            closeAutocomplete();
            onSearch(term);
        },
        [onSearch]
    );

    const handleMouseDown = useCallback(
        (event) => {
            event.preventDefault(); // keeps focus on input element
        },
        []
    );

    const handleKeyDown = (event) => {
        if (event.keyCode === KEY_ESC) {
            closeAutocomplete();
        } else if (event.keyCode === KEY_DOWN && isAutocompleteActivated) {
            event.preventDefault();
            event.stopPropagation();
            focusFirstAutocompleteSuggestion();
        }
    };

    const focusFirstAutocompleteSuggestion = () => {
        let suggestions = document.getElementsByClassName('ob-search-bar-autocomplete__result-item-button');
        if (suggestions && suggestions.length > 0) {
            suggestions[0].focus();
        }
    };

    const handleSuggestionItemKeyDown = (event) => {
        let selectedSuggestionItemIndex = getSelectedSuggestionItemIndex();
        if (event.keyCode === KEY_ESC) {
            inputRef.current.focus();
            closeAutocomplete();
            return;
        }

        if (event.keyCode === KEY_DOWN) {
            event.preventDefault();
            focusSuggestionItemByIndex(selectedSuggestionItemIndex + 1);
        } else if (event.keyCode === KEY_UP) {
            event.preventDefault();
            focusSuggestionItemByIndex(selectedSuggestionItemIndex - 1);
        }
    };

    const focusSuggestionItemByIndex = (index) => {
        let suggestions = document.getElementsByClassName('ob-search-bar-autocomplete__result-item-button');
        if (suggestions && suggestions.length > 0) {
            if (index < 0) {
                index = suggestions.length - 1;
            }
            if (index >= suggestions.length) {
                index = 0;
            }

            suggestions[index].focus();
        }
    };

    const getSelectedSuggestionItemIndex = () => {
        if (document.activeElement) {
            let index = document.activeElement.getAttribute('data-index');
            if (index) {
                return parseInt(index);
            }
        }
        return 0;
    };

    const handleSubmit = useCallback(
        (event) => {
            event.preventDefault();

            if (inputRef.current) {
                let term = '';
                if (inputRef.current.value) {
                    term = inputRef.current.value.trim();
                }

                inputRef.current.blur();
                closeAutocomplete();
                onSearch(term);
            }
        },
        [onSearch]
    );

    return (
        <form className="ob-search-bar-form" onSubmit={handleSubmit} key={defaultValue}>
            {searchTitle !== '' &&
                <h1 id={SearchBarConstants.searchTitle} className="ob-search-block__search-title">
                    {searchTitle.toUpperCase()}
                </h1>
            }
            <div className="ob-search-bar">
                <input type={SearchBarConstants.text}
                       id={SearchBarConstants.searchText}
                       name={SearchBarConstants.searchText}
                       ref={inputRef}
                       autoComplete='off'
                       aria-labelledby={SearchBarConstants.searchTitle}
                       onInput={handleInput}
                       onKeyDown={handleKeyDown}
                       placeholder={placeholder}
                       defaultValue={defaultValue || ''}  />

                <button aria-label={searchCtaLabel} type={SearchBarConstants.submit} title={searchActionTitle} className="ob-search-bar-icon">
                    {isSpinning ?
                        <div className="ob-search-bar-spinner"><div/></div> :
                        <Icon name={SearchBarConstants.search} arialable={'Search'}/>}
                    <span className="visuallyhidden">{searchCtaLabel}</span>
                </button>

                {(isAutocompleteActivated && autoCompleteResults && autoCompleteResults.length > 0) && (
                    <div role={SearchBarConstants.list} onMouseDown={handleMouseDown} className="ob-search-bar-autocomplete">
                        <div className="ob-search-bar-autocomplete-title">
                            {commonSearchesLabel}
                        </div>

                        <ul className="ob-search-bar-autocomplete-results">
                            {autoCompleteResults.map((autocompleteResult, i) => (
                                <li key={i} className={'ob-search-bar-autocomplete__result-item'}>
                                    <button className={'ob-search-bar-autocomplete__result-item-button event_search'}
                                            data-index={i}
                                            onKeyDown={handleSuggestionItemKeyDown}
                                            data-action-detail={stripHtml(autocompleteResult.title)}
                                            role="link"
                                            onClick={() => handleAutocompleteResultClick(autocompleteResult.query)} dangerouslySetInnerHTML={{ __html: autocompleteResult.title }} />
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
        </form>
    );
}

SearchBar.propTypes = {
    onSearch: PropTypes.func,
    placeholder: PropTypes.any,
    searchActionTitle: PropTypes.string,
    defaultValue: PropTypes.string,
    commonSearchesLabel: PropTypes.string,
    isSpinning: PropTypes.bool,
    isSearchFaqOnly: PropTypes.bool,
    isAutocompleteEnabled: PropTypes.bool,
    searchTitle: PropTypes.string,
    searchCtaLabel: PropTypes.string
};

SearchBar.defaultProps = {
    searchActionTitle: '',
    searchTitle: '',
    defaultValue: '',
    commonSearchesLabel: '',
    isAutocompleteEnabled: true,
};
