import React, {useContext} from 'react';
import Block from '../../adapters/helpers/Block';
import PropTypes from 'prop-types';
import LayoutContext from '../Layout/LayoutContext';
import Icon from '../Icon/Icon';
import Image from '../Image/Image';
import { mediaQueryHOC } from '../../adapters/helpers/Hooks'
import {SpotlightContestConstants} from '../../adapters/helpers/Constants';
import ObLink from '../ObLink/ObLink';

function SpotlightContestThankYou(props) {
    const { document: entity, isMedium } = props;

    const block = new Block(entity);
    
    
    const bannerAssetMobile = props?.document?.fields?.bannerAssetMobile;
    const bannerAsset = props?.document?.fields?.bannerAsset;
    const backgroundAsset = props?.document?.fields?.backgroundAsset;
    const thankYouHeading = props?.document?.fields?.thankYouTitle;
    const arrowTitle = props?.document?.fields?.arrowTitle;
    const title = props?.document?.fields?.title;
    const thankYouSubtitle = props?.document?.fields?.thankYouSubtitle;
    const thankYouDescription= props?.document?.fields?.thankYouDescription;
    const oralBLogoAsset = block.getFieldValue(SpotlightContestConstants.oralBLogoAsset);
    const copyrightLabel = block.getFieldValue(SpotlightContestConstants.copyrightLabel);
    const socialLinks = block.getFieldValue(SpotlightContestConstants.socialLinks);
    const layoutContext = useContext(LayoutContext);
    const { onContestScrollDown } = layoutContext;
    const backToOralbLink = block.getFieldValue(SpotlightContestConstants.backToOralbLink);
    
    let canScrollToNextSection = false;
    let scrollDownDebounceTimeout = null;
    
    const handleWheelScroll = (event) => {
        const element = event.currentTarget;
        const deltaY = event.deltaY;
        if ((element.scrollHeight - element.scrollTop) === element.clientHeight) {
            if (deltaY >= 100) {
                manageScrollToNextSection();
            }
        } else {
            canScrollToNextSection = false;
        }
    };
    
    const handleTouchMoveEnd = (event) => {
        const element = event.currentTarget;
        if ((element.scrollHeight - element.scrollTop) === element.clientHeight) {
            manageScrollToNextSection();
        } else {
            canScrollToNextSection = false;
        }
    };
    
    const manageScrollToNextSection = () => {
        if (!canScrollToNextSection) {
            debounceScrollDown();
        } else {
            canScrollToNextSection = false;
            onContestScrollDown();
        }
    };
    
    const debounceScrollDown = () => {
        if (scrollDownDebounceTimeout) {
            clearTimeout(scrollDownDebounceTimeout);
        }
        
        scrollDownDebounceTimeout = setTimeout(function () {
            canScrollToNextSection = true;
        }, 150);
    };

    return (
        <div onWheel={handleWheelScroll} onTouchEnd={handleTouchMoveEnd} className={'ob-spotlightContentBlock ob-spotlight-contest js-modal-container ob-spotlight-thankyou'}>
            <div className='ob-spotlight_contest-background'
                style={{position: 'absolute', top: '0', bottom: '0', width: '100%', height: '110%', zIndex: '0'}}>
                <Image forceBackground={true} image={backgroundAsset} customStyles={{backgroundRepeat: 'no-repeat'}} />
            </div>
            <div className='ob-spotlight_banner'>
                <Image image={(bannerAsset && !isMedium) ? bannerAsset : bannerAssetMobile} customStyles={{backgroundRepeat: 'no-repeat'}} />
            </div>
            <div className='ob-spotlight_contest-container'>
                <div className={'ob-spotlight-contest__title-image-buffer'}><span className='visuallyhidden'>{title}</span></div>
                <div className={'ob-spotlight-contest__contest-title-container'}>
                    <h1 className={'ob-spotlight-contest__contest-title-text'}>
                        <span dangerouslySetInnerHTML={{__html: thankYouHeading}}></span><span className={'ob-spotlight-contest__contest-title-io-logo'}></span>
                    </h1>
                     
                    <div className='ob-spotlight-thankyou_subtitle'>
                        <span dangerouslySetInnerHTML={{__html: thankYouSubtitle}}></span>
                    </div>
                </div>

                <div className='ob-spotlight-thankyou_hero'>
                    <div dangerouslySetInnerHTML={{__html: thankYouDescription}}></div>
                </div>
 
                <div className={'ob-spotlight-contest__wrapper'}>

                    {(oralBLogoAsset || socialLinks || copyrightLabel) &&
                        <div className={'ob-spotlight-contest__footer'}>
                            {socialLinks &&
                            <div className={'ob-spotlight-contest__footer-social-links'}>
                                {socialLinks.map((link, linkIndex) => (
                                    <div className={'ob-spotlight-contest__footer-social-links-item'} key={linkIndex}>
                                        <a href={link.fields.url} target={'_blank'}>
                                            <Image image={link.fields.image} noLazyLoad={true} />
                                        </a>
                                    </div>
                                ))}
                            </div>
                            }
                            <div className={'ob-spotlight-contest__footer-oralb-logo'}>
                                <ObLink tag={'a'} href={backToOralbLink?.fields?.url}>
                                    <Image image={backToOralbLink?.fields?.image} noLazyLoad={true} />
                                </ObLink>
                            </div>
                            {copyrightLabel &&
                                <div className={'ob-spotlight-contest__footer-copyright'} dangerouslySetInnerHTML={{__html: copyrightLabel}} />
                            }
                        </div>
                    }
                    
                    <div className={'ob-spotlight-contest__button-scroll-down-container'}>
                        <button className={'ob-spotlight-contest__button-scroll-down'} onClick={onContestScrollDown}>
                            <span dangerouslySetInnerHTML={{__html: arrowTitle}}></span>
                            <Icon name={SpotlightContestConstants.chevronDown} size={ isMedium ? '4' : '5.8'} color={'white'} />
                        </button>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default mediaQueryHOC(SpotlightContestThankYou);
// This export is for unit testing (do not remove) :
export const SpotlightContestThankYouTest = SpotlightContestThankYou;

SpotlightContestThankYou.propTypes = {
    document: PropTypes.object,
    isMedium: PropTypes.bool,
    index: PropTypes.any
};
