import MarkdownText from '../MarkdownText/MarkdownText';
import React from 'react';
import PropTypes from 'prop-types';

import Block from '../../adapters/helpers/Block';
import Component from '../../adapters/helpers/entity/Component';
import { FaqDetailsBlockConstants } from '../../adapters/helpers/Constants';
import { mediaQueryHOC } from '../../adapters/helpers/Hooks';

function FAQDetailsBlock(props) {
    const {extraAttributes} = props;
    const entityFAQ = extraAttributes?.entity;

    if (!entityFAQ) {
        return null;
    }

    const block = new Block(props);
    const faq = new Component(entityFAQ);

    const title = faq?.props?.question;
    const body = faq?.props?.answer;
    const classNames = FaqDetailsBlockConstants.baseClass + block.getFieldValue(FaqDetailsBlockConstants.classNames);
    const anchorId = block.getFieldValue(FaqDetailsBlockConstants.anchorId);
    
    return (
        <div id={anchorId} className={classNames}>
            <h1>{title}</h1>
            <div className={'ob-faq-details__body'}>
                <MarkdownText
                    className={'ob-faq-details__body-text'}
                    value={body}
                />
            </div>
        </div>
    )
}

export default mediaQueryHOC(FAQDetailsBlock);

FAQDetailsBlock.propTypes = {
    extraAttributes: PropTypes.any
};
