import React, { useEffect, useRef } from 'react';
import Block from '../../adapters/helpers/Block';
import ButtonTabsNavigation from '../ButtonTabsNavigation/ButtonTabsNavigation';
import {getSelectedIndex, mapLinks} from './helpers';
import Eyebrow from '../Eyebrow/Eyebrow';
import PropTypes from 'prop-types';
import { scrollIntoView } from '../../adapters/helpers/Scroll';

export default function CollectionTabsDropdown(props) {
    const {extraAttributes, document} = props;

    const block = new Block(props);
    
    const title = document?.fields?.title;
    const extraClassNames = document?.fields?.classNames;
    const links = document?.fields?.links;
    const anchorId = block.getAnchorId();
    const currentURL = extraAttributes?.slug;
    const tabsRef = useRef();

    const classNames = ['ob-collection-tabs-dropdown'];
    if (extraClassNames) {
        classNames.push(extraClassNames);
    }

    const tabs = mapLinks(links);
    const selectedIndex = getSelectedIndex(links, currentURL.replace(/\/$/, ''));

    useEffect(() => {
        if (window?.location?.hash === `#${anchorId}` && tabsRef?.current) {
            let timeout = null;
            window.setTimeout(() => {
                timeout = scrollIntoView(tabsRef.current, {behavior: 'smooth'});
            }, 250);

            return () => {
                clearTimeout(timeout);
            }
        }
    }, []);

    return (
        <div className={classNames.join(' ')} id={anchorId} ref={tabsRef}>
            <Eyebrow className="ob-collection-tabs-dropdown__eyebrow">{title}</Eyebrow>
            <div className="ob-collection-tabs-dropdown__tabs-wrapper">
                <ButtonTabsNavigation tabs={tabs} selectedIndex={selectedIndex}/>
            </div>
        </div>
    )
}

CollectionTabsDropdown.propTypes = {
    extraAttributes: PropTypes.any,
    document: PropTypes.any,
}