import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Icon from '../Icon/Icon';
import {PypestreamChatConstants} from '../../adapters/helpers/Constants';
import { AnalyticsConstants } from '../../adapters/helpers/ConstantsGA';
import Modal from 'react-modal';
import Image from '../Image/Image';

let windowObjectReference = null;

function PypestreamChat(props) {
    /* global Pypestream */

    const modalDelay = props.document?.fields?.modalDelay;
    const popUpDisabled = props.document?.fields?.popUpDisabled || false;
    const moduleId = props.document?.fields?.moduleId;
    const modalTitle = props.document?.fields?.modalTitle;
    const modalContent = props.document?.fields?.modalContent;
    const modalBackgroundImage = props.document?.fields?.modalBackgroundImage;
    const modalAcceptButtonLabel = props.document?.fields?.modalAcceptButtonLabel;
    const modalDeclineButtonLabel = props.document?.fields?.modalDeclineButtonLabel;
    const toggleChatLabel = props.document?.fields?.toggleChatLabel;
    const toggleButtonIcon = props.document?.fields?.toggleButtonIcon;
    const apiScript = props.document?.fields?.apiScriptUrl;
    const configId = props.document?.fields?.configId;
    const configDomain = props.document?.fields?.configDomain;
    const configEnv = props.document?.fields?.configEnv;
    const configBeta = props.document?.fields?.configBeta;
    const localStorageVariable =  PypestreamChatConstants.persistentChatTriggered + moduleId;
    const proChat = props.document?.fields?.proChat;
    const closeButtonIcon = props.document?.fields?.closeModel;

    const customModalStyles = {
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 200
        },
        content : {
            background          : 'none',
            border              : 'none',
            borderRadius        : '0',
            top                 : '50%',
            left                : '50%',
            right               : 'auto',
            bottom              : 'auto',
            fontSize            : '1.4rem',
            lineHeight          : '2.1rem',
            marginRight         : '-50%',
            padding             : '0',
            maxWidth            : '530px',
            transform           : 'translate(-50%, -50%)',
            width               : '90%'
        }
    };

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalWasVisited, setModalWasVisited] = useState(popUpDisabled);
    const [proChatVisited, setProChatVisited] = useState(false);
    const [visibility, setVisibility] = useState(true);
    const [macStyle, setMacStyle] = useState(false);
    const [isBusinessHours, setIsBusinessHours] = useState(false);
    const [chatUrl, setChatUrl] = useState('');
    const [isAgentActive, setIsAgentActive] = useState(false);

    const launchChat = () => {
        let chatContainer = document.getElementById(moduleId +'-module-container');

        closeModal();

        Pypestream('config', {
            domain: configDomain,
            env: configEnv,
            beta: configBeta
        });

        Pypestream('boot', {
            APP_ID: configId
        }, chatContainer);
    };

    const toggleChatLink = () => {
        window.open(apiScript, 'newwindow', 'width=300,height=400, bottom=0')
    };

    const toggleChat = () => {
        if(chatWasVisited) {
            Pypestream('toggle');
        } else {
            launchChat();
        }
    };

    const closeChatBot = (e) => {
        setModalWasVisited(false);
        e.stopPropagation();
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setModalWasVisited(true);
        localStorage.setItem(localStorageVariable, true);
    };

    const appendScript = () => {
        const script = document.createElement('script');
        script.src = apiScript;
        script.async = true;
        document.head.appendChild(script);
    };

    const openChart = () => {  
        console.log('openChart log');       
        if(windowObjectReference == null || windowObjectReference.closed) {
          windowObjectReference = window.open(apiScript, '', 'width=400,height=600,top=150,left=10');
        } else {
          windowObjectReference.focus();
        }
    }

    useEffect(() => {
        fetch(process.env.LIVECHAT_IFRAME_URL)
        .then(res => res.json())
        .then((result) => {
              setIsBusinessHours(result.withinBusinessHours);
              setChatUrl(result.chatUrl);
              setIsAgentActive(result.isAgentActive);
        }).catch((error) => {
            console.error(error);
        });
        appendScript();
        const persistentChatTriggered = localStorage.getItem(localStorageVariable);
        const macOs = navigator && navigator.userAgent && navigator.userAgent.indexOf('Mac OS X') != -1;
        setMacStyle(macOs);

        if(!popUpDisabled) {
            setModalWasVisited(persistentChatTriggered ? true : false);
        }

        if (!persistentChatTriggered && !popUpDisabled) {
            setTimeout(() => {
                setModalIsOpen(true);
            }, (modalDelay*1000));
        }
    }, []);

    let chatStatus = isBusinessHours && isAgentActive ? PypestreamChatConstants.chatNow : isBusinessHours && !isAgentActive ? PypestreamChatConstants.chatBusy : !isBusinessHours && !isAgentActive ? PypestreamChatConstants.chatClosed : '';

    return (
        <div>
             {modalWasVisited && 
                <div className={` ${macStyle ? 'mac-pypestream-toggle':''} ob-pypestream-toggle-button event_button_click ${visibility ? 'display-block' : 'hidden-block'}`}
                data-action-detail={AnalyticsConstants.openLiveChat}>
                <div id="close_pypestream" role='button' tabIndex={0} className="ob-app-store__close-button event_button_click" onClick={() => setVisibility()} onKeyDown={() => setVisibility()} 
                    aria-label={'close'}>
                    <Image image={closeButtonIcon} />
                </div>
                <div tabIndex={0} aria-label={toggleChatLabel.fields.text} 
                className={chatStatus === PypestreamChatConstants.chatNow ? 'ob-pystream-cursorpoint' : 'ob-pystream-cursorarrow'}
                onClick={() => proChat ? isBusinessHours && isAgentActive ? openChart() : '' : toggleChat()}>
                    <div className="ob-pystream-chat-content">
                        {modalTitle &&
                            <p className="ob-pystream-title">{modalTitle}</p>
                        }
                        {modalContent && <div>
                            <p className="ob-pystream-subtitle">{modalContent}</p>
                            <p className="ob-pystream-chatstatus">{PypestreamChatConstants.chatIsOpen}</p>
                            <p className="ob-pystream-subchatstatus">{PypestreamChatConstants.chatTime}</p></div>
                        }
                        <button className={`ob-chat-btn-status ${chatStatus === PypestreamChatConstants.chatNow ? 'ob-pystream-cursorpoint' : 'ob-pystream-cursorarrow'}`}>{chatStatus}</button>
                    </div>
                    <Image image={toggleButtonIcon} />
                </div>
                </div>
             }
                       

            <div className={'ob-pypestream-chat-container'}
                 id={moduleId + '-module-container'}>
            </div>

            {!modalWasVisited &&
                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    closeTimeoutMS={250}
                    style={customModalStyles}>
                    <div className='ob-pypestream-modal'>
                        <Image image={modalBackgroundImage}>
                            <div className='ob-pypestream-modal-content'>
                                <div className='ob-pypestream-modal-copy'>
                                    {modalTitle &&
                                        <p>{modalTitle}</p>
                                    }
                                    {modalContent &&
                                        <p>{modalContent}</p>
                                    }
                                </div>

                                <div className='ob-pypestream-modal-button-container'>
                                    <button aria-label={modalAcceptButtonLabel} className='ob-pypestream-modal-button launch'
                                            onClick={launchChat}>
                                        {modalAcceptButtonLabel}
                                    </button>

                                    <button aria-label={modalDeclineButtonLabel} className='ob-pypestream-modal-button close'
                                            onClick={closeModal}>
                                        {modalDeclineButtonLabel}
                                    </button>
                                </div>
                            </div>
                        </Image>
                    </div>
                </Modal>
            }
        </div>
    );
}

export default PypestreamChat;

PypestreamChat.propTypes = {
    document: PropTypes.object
};